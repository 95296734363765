<template>
  <div>
    <v-card flat class="grey lighten-5">
        <v-container class="grey lighten-5 fill-height">
            <v-card :shaped="true" :raised="true" width="100%" style="margin-bottom: 25px">
                <!-- <v-card :shaped="true" > -->
                <div class="align-center" style="background: #FFFFFF; box-shadow: 0px 3px 12px #0000000F; margin-bottom: 16px; width: 100%; height: 80px;">
                    <v-row>
                        <v-col :md="1" class="ml-4">
                            <v-avatar style="background: transparent linear-gradient(142deg, #FFA63E 0%, #EA4D2C 100%) 0% 0% no-repeat padding-box;">
                                <v-icon size="15">$vuetify.icons.voucher</v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col :md="7">
                            <div class="title" style="color: #F16B32">Haircut - Short With Sabana Treatment</div>
                            <div class="subtitle-1" style="color: #6A6A6A">Djemari Salon and Spa</div>
                        </v-col>
                        <v-col :md="3" class="text-right">
                            <v-btn rounded color="#00D79E" style="color: #FFFFFF">{{ lang.t('$vuetify.edit_hot_promo') }}</v-btn>
                            <v-btn color="#FDF1EF" fab small class="ma-2 white--text" style="border: 0.1px solid #E74C3C;">
                                <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <!-- </v-card> -->
                    <v-row class="mt-4 ml-6">
                        <v-col :md="4" class="mt-3">
                            <v-img
                                height="304"
                                width="376"
                                src="@/assets/adult-barber.png"
                            ></v-img>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col :md="4">
                                    <div class="text-center pt-2" style="width: 168px; height: 80px;border: 2px solid #F16B32;border-radius: 8px;">
                                        <div class="body-1">{{ lang.t('$vuetify.discounted_price') }}</div>
                                        <div class="headline font-weight-bold" style="color: #F16B32">Rp. 80.000</div>
                                    </div>
                                </v-col>
                                <v-col :md="3">
                                    <div class="mt-3">
                                        <div class="body-1" style="color: #BEC2C4">{{ lang.t('$vuetify.discount') }}</div>
                                        <div class="body-1 font-weight-bold">30%</div>
                                    </div>
                                </v-col>
                                <v-col>
                                    <div class="mt-3">
                                        <div class="body-1" style="color: #BEC2C4">{{ lang.t('$vuetify.normal_price') }}</div>
                                        <div class="body-1 font-weight-bold">Rp100.000</div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top: -15px">
                                <v-col :md="10">
                                    <v-row>
                                        <v-col :md="5">
                                            <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.priority_level') }}</span>
                                        </v-col>
                                        <v-col>
                                            <span class="subtitle-1">1</span>
                                        </v-col>
                                    </v-row>
                                    <hr style="border-top: 1px solid #BEC2C4; margin-top: -10px">
                                    <v-row style="margin-top: -5px">
                                        <v-col :md="5">
                                            <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.minimum_purchase') }}</span>
                                        </v-col>
                                        <v-col>
                                            <span class="subtitle-1">Rp50.000</span>
                                        </v-col>
                                    </v-row>
                                    <hr style="border-top: 1px solid #BEC2C4; margin-top: -10px">
                                    <v-row style="margin-top: -5px">
                                        <v-col :md="5">
                                            <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.voucher_limit') }}</span>
                                        </v-col>
                                        <v-col>
                                            <span class="subtitle-1">300</span>
                                        </v-col>
                                    </v-row>
                                    <hr style="border-top: 1px solid #BEC2C4; margin-top: -10px">
                                    <v-row style="margin-top: -5px">
                                        <v-col :md="5">
                                            <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.max_usage_customer') }}</span>
                                        </v-col>
                                        <v-col>
                                            <span class="subtitle-1">3 times</span>
                                        </v-col>
                                    </v-row>
                                    <hr style="border-top: 1px solid #BEC2C4; margin-top: -10px">
                                    <v-row style="margin-top: -5px">
                                        <v-col :md="5">
                                            <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.date_period') }}</span>
                                        </v-col>
                                        <v-col>
                                            <span class="subtitle-1">22 Aug 2020 - 22 Jan 2021</span>
                                        </v-col>
                                    </v-row>
                                    <hr style="border-top: 1px solid #BEC2C4; margin-top: -10px">
                                    <v-row style="margin-top: -5px">
                                        <v-col :md="5">
                                            <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.day') }}</span>
                                        </v-col>
                                        <v-col>
                                            <span class="subtitle-1">Every Day</span>
                                        </v-col>
                                    </v-row>
                                    <hr style="border-top: 1px solid #BEC2C4; margin-top: -10px">
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                <p class="font-weight-bold title ml-6 mt-8" > {{ lang.t('$vuetify.location') }} </p>
                <v-row align="center" class="mx-4 mb-4">
                    <v-col v-for="i in 8" :key="i" class="mr-2" :md="5">
                        <div style="width: 392px; height: 64px; border: 1px solid #BEF4E6; border-radius: 10px;">
                            <v-row>
                                <v-col :md="7" class="ml-4 my-2">Anissa Salon Spa</v-col>
                                <v-col :md="4" class="text-center ml-2 py-4" style="height: 62px; background: #F8F8F8; border-top-right-radius: 10px; border-bottom-right-radius: 10px;">
                                <span class="subtitle-1">Batam</span>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'

// import LocationDetail from './_location-detail'
// import LocationStaff from './_location-staff'
// import LocationSales from './_location-sales'
export default {
  name: 'ReportMerchant',
  data () {
    return {
      search: '',
      tab: null,
      tab2: null,
      address: 'Jalan Akasia, Jl. Seturan Raya No.1A, Kledokan, Condongcatur, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55281',
      items: [
        'Merchant List', 'Activity', 'Transaction', 'Acquisition'
      ],
      itemsTab: [
        'Profile', 'Location', 'Staff', 'Sales'
      ],
      alignments: [
        'center'
      ],
      merchantList: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        }
      ],
      headers: [
        {
          text: 'Merchant Name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Total Staff',
          align: 'center',
          value: 'total_staff'
        },
        {
          text: 'Platform',
          align: 'left',
          value: 'platform'
        },
        {
          text: 'City',
          align: 'left',
          value: 'city'
        },
        {
          text: 'Time Joined',
          align: 'right',
          value: 'time_joined'
        }
      ],
      desserts: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS, Online',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS, Online, Marketplace',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          total_staff: 12,
          platform: 'POS',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        }
      ],
      title: {
        text: 12,
        textStyle: {
          color: '#333333',
          fontSize: '69',
          fontStyle: 'normal'
        },
        x: 'center',
        y: 'center'
      },
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        offsetY: 150,
        radius: [90, 130],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false
      },
      extend: {
        'xAxis.0.axisLabel.rotate': 15
      },
      colorsRing: ['#FFCB9F', '#FFE78F', '#A1FFD1', '#C5DBFF'],
      colorsLine: ['#00D79E', '#1DA1F3'],
      dataRing: {
        columns: ['date', 'sales'],
        rows: [{ 'date': '10', 'sales': 20 },
          { 'date': '11', 'sales': 45 },
          { 'date': '12', 'sales': 30 },
          { 'date': '13', 'sales': 40 }
        ]
      },
      histogramSettings: {
        metrics: ['confirmed', 'cancelled'],
        stack: { 'sales': ['confirmed', 'cancelled'] }
      },
      colorsHisto: ['#00D79E', '#F5295A'],
      dataUpcomingAppointment: {
        columns: ['date', 'confirmed', 'cancelled'],
        rows: [{ 'date': 'Mon 10', 'confirmed': 2, 'cancelled': 2 },
          { 'date': 'Tue 11', 'confirmed': 2, 'cancelled': 0 },
          { 'date': 'Wed 12', 'confirmed': 4, 'cancelled': 0 },
          { 'date': 'Thr 13', 'confirmed': 2, 'cancelled': 0 },
          { 'date': 'Fri 14', 'confirmed': 6, 'cancelled': 2 },
          { 'date': 'Sat 15', 'confirmed': 7, 'cancelled': 0 },
          { 'date': 'Sun 16', 'confirmed': 3, 'cancelled': 2 }
        ]
      },
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      selectCity: '',
      page: 1,
      total_page: 10
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    },

    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>
